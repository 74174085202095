import request from '@/utils/axios'

export function getAppFileHistory() {
  return request({
    url: '/api/appfile/history',
    methods: 'GET',
    params: {
      page: 1,
      pageSize: 10
    }
  })
}
