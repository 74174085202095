<template>
  <div class="downloadPage">
    <div class="downloadPageContainer">
      <div class="left">
        <img
          src="@/assets/downloadPage/downloadLogo.png"
          alt=""
        >
      </div>
      <div class="right">
        <div class="downloadBlock">
          <div class="top">
            <div class="topLeft">
              <img
                src="@/assets/downloadPage/downloadIcon.png"
                alt=""
              >
            </div>
            <div class="topRight">
              <span class="downloadSpan">
                直接下載 APP
              </span>
            </div>
          </div>

          <div class="bottom">
            <div class="bottomLeft">
              <div class="logo">
                <img
                  src="@/assets/downloadPage/apple.svg"
                  alt=""
                >
              </div>
              <div class="content">
                <div class="contentTop">IOS</div>
                <div class="contentBottom">即将推出</div>
              </div>
            </div>

            <div
              class="bottomRight"
              @click="downloadAPK"
            >
              <div class="logo">
                <img
                  class="android"
                  src="@/assets/downloadPage/android.svg"
                  alt=""
                >
              </div>
              <div class="androidContent">
                <div class="single">Android</div>
              </div>
            </div>
          </div>
        </div>

        <div class="updateListBlock">
          <div>
            更新LOG:
          </div>
          <div
            class="updateBlock"
            v-for="(it,key) in upateList"
            :key="key"
          >
            <div class="row">========================================</div>
            <div class="row versionRow">{{it.version}} :</div>
            <div class="row">{{it.remark}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAppFileHistory } from '@/api/client/downloadPage'
export default {
  data() {
    return {
      upateList: []
    }
  },
  created () {
    this.callGetAppFileHistory()
  },
  methods: {
    callGetAppFileHistory() {
      getAppFileHistory().then(res => {
        if (res.status === this.$resStatus.OK) {
          this.upateList = res.data
          // 測試假資料
          // for (let i = 0; i < 5; i++) {
          //   const fakeData = Object.assign({}, res.data[0])
          //   this.upateList.push({
          //     ...fakeData,
          //     ...{
          //       filename: `file: ${Math.floor(Math.random() * 1000)}`,
          //       remark: `這是remark ${Math.floor(Math.random() * 1000)}`
          //     }
          //   })
          // }
        }
      })
    },
    downloadAPK() {
      const fileURL = this.$common.resSrc(this.upateList[0].file_url)
      window.location.href = fileURL
    }
  }
}
</script>

<style lang="scss" scoped>
.downloadPage {
  width: 100%;
  .downloadPageContainer {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    font-size: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1140px;
    @media (max-width: 767.98px) {
      max-width: unset;
    }
    .left {
      width: 50%;
      @media (max-width: 767.98px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: auto;
        @media (max-width: 767.98px) {
          width: 60%;
        }
      }
    }
    .right {
      width: 50%;
      @media (max-width: 767.98px) {
        width: 100%;
      }
      .downloadBlock {
        width: 81%;
        margin: 0 auto;
        margin-top: 20px;
        @media (max-width: 767.98px) {
          margin-top: 0px;
        }
        .top {
          display: flex;
          align-items: center;
          .topLeft {
            display: flex;
            align-items: center;
            margin-right: 17px;
            @media (max-width: 767.98px) {
              margin-right: 10px;
            }
            img {
              @media (max-width: 767.98px) {
                width: 30px;
              }
            }
          }
          .topRight {
            width: 85%;
            color: #005fde;
            text-align: left;
            display: flex;
            align-items: center;
            .downloadSpan {
              @media (max-width: 767.98px) {
                font-size: 20px;
              }
            }
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          margin-top: 56px;
          @media (max-width: 767.98px) {
            margin-top: 22px;
          }
          .bottomLeft,
          .bottomRight {
            padding: 10px 25px;
            display: flex;
            font-size: 20px;
            justify-content: center;
            align-items: center;
            height: 75px;
            color: #ffffff;
            width: 50%;
            @media (max-width: 1030px) {
              font-size: 16px;
            }
            @media (max-width: 767.98px) {
              height: 48px;
              padding: 5px 15px;
              font-size: 14px;
            }
            @media (max-width: 900px) {
              padding: 5px 8px;
            }
            @media (max-width: 350px) {
              font-size: 12px;
            }
            .logo {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 40%;
              height: 100%;
              img {
                position: relative;
                transform: scale(1.5);
                margin-right: 5px;
                @media (max-width: 767.98px) {
                  transform: scale(1.1);
                  margin-right: 0px;
                }
              }
            }

            .content {
              width: 60%;
              height: 100%;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              white-space: nowrap;
              .contentTop,
              .contentBottom {
                width: 100%;
                text-align: left;
                letter-spacing: 1px;
                color: #ffffff;
                text-shadow: 0px 3px 6px #00000029;
              }
            }
            .androidContent {
              width: 50%;
              height: 100%;
              display: flex;
              align-items: center;
              @media (max-width: 900px) {
                width: 40%;
              }
            }
          }

          .bottomLeft {
            background: transparent
              linear-gradient(180deg, #d9d9d9 0%, #a7a7a7 100%) 0% 0% no-repeat
              padding-box;
            border: 2px solid #b0b0b0;
            border-radius: 20px;
            margin-right: 10px;
            cursor: not-allowed;
            @media (max-width: 767.98px) {
              border-radius: 15px;
              margin-right: 6px;
            }
          }
          .bottomRight {
            background: transparent
              linear-gradient(180deg, #579fff 0%, #3481e8 100%) 0% 0% no-repeat
              padding-box;
            border: 2px solid #2561d6;
            border-radius: 20px;
            cursor: pointer;
            margin-left: 10px;
            @media (max-width: 767.98px) {
              border-radius: 15px;
              margin-left: 6px;
            }
          }
        }
      }
      .updateListBlock {
        width: 81%;
        text-align: left;
        font-size: 18px;
        margin: 0 auto;
        margin-top: 56px;
        padding: 11px;
        padding-top: 30px;
        color: #707070;
        max-height: 320px;
        overflow: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 6px #0000001a;
        border-radius: 5px;
        @media (max-width: 767.98px) {
          margin-top: 22px;
          font-size: 14px;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9eff5;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #b1b5b9;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #7a7c7e;
        }
        .updateBlock {
          width: 100%;
          margin: 0 auto;
          margin-top: 25px;
          .row {
            width: 100%;
            margin: 0 auto;
            white-space: pre-line;
            word-break: break-all;
            overflow-x: hidden;
          }
          .versionRow {
            margin-top: 5px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
